
.nav-buttons {
    margin: 20px 0;
    text-align: center;
}

.nav-buttons button {
    border: 1px solid black;
    border-radius: 20px;
    padding: 10px 20px;
    margin: 0 10px;
    background-color: white;
    font-weight: bold;
}

.nav-buttons button.active {
    background-color: black;
    color: white;
}

.product-card {
    text-align: center;
    margin-bottom: 20px;
    width: 220px;
}

.product-card img {  
    width: 200px;
    height: auto;
    transition: transform 0.3s ease;
}

.product-card:hover img {
    transform: scale(1.05);
}

.product-card .product-title {
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
}

.product-card .product-price {
    font-size: 12px;
    color: grey;
}

.product-card .product-sizes {
    font-size: 10px;
    color: grey;
}

.product-card img.secondary {
    display: none;
}

.product-card:hover img.primary {
    display: none;
}

.product-card:hover img.secondary {
    display: block;
}
@media (max-width: 526px) {
    .product-card {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .product-card img {  
        width: 140px;
        height: auto;
        transition: transform 0.3s ease;
    }
    .product-card .product-title{
        font-size: 12px;
    }
    .product-card .product-price{
        font-size: 12px !important;
    }
  }
  
  .filters-section {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
  }
  
 @media (max-width:986px){
    .range,.sort,.filter{
        font-size: 12px !important;
    }
 }
 .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .spinner {
    border: 4px solid transparent;
    border-top: 4px solid #007bff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media (max-width : 548px){
    .left{ 
     font-size: 10px;
  }
  }
  