/* Remove hover effect (box shadow) */
.card:hover {
    box-shadow: none;
  }
  
  /* Optional: Remove hover transform effect if applied */
  .card:hover {
    transform: none;
  }
 @media(max-width: 648px){
  .card{ 
       width: auto;
        margin-top: 1rem;
    } 
    .dashboard h5{
      font-size: 1rem;
    }
    .category,.editcategory h5{
      font-size: 1rem;
    }
    .dashboard,.category,.editcategory h4{
      font-size: .8rem;
    }
    .dashboard,.category,.editcategory .btn{
      font-size: .8rem;
      padding: 6px;
    }
    .category, .table{
      font-size: 12px;
    }
    .category,.product,.user .table .btn{
       font-size: 8px; 
    }
    .editcategory,.editprofile,.edituser .btn-warning{
       margin-top: .5rem;
    }
     .complete{
      font-size: 10px;
     }
 }
 .complete{
  min-width: 5rem !important;
  margin: 2px 0px;
 }

 .loader-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
 
.loader {
  margin-top: 20px;
  font-size: 16px;
  color: #555;
  text-align: center;
}