/* body {
    background-color: #f8f9fa;
    font-family: 'Arial', sans-serif;
  } */
  
  .footer {
    background-color: #d4cccc;
    padding: 40px 0;
    text-align: center;
    border-top: 1px solid #e5e5e5;
  }
  
  .footer .line {
    width: 50px;
    height: 1px;
    background-color: #000;
    margin: 0 auto 20px;
  }
  
  .footer .social-icons i {
    font-size: 20px;
    margin: 0 10px;
    color: #000;
  }
  
  .footer .newsletter input[type="email"] {
    border: 1px solid #000;
    padding: 5px 10px;
    width: 200px;
    margin-right: 10px;
  }
  
  .footer .newsletter input[type="submit"] {
    border: 1px solid #000;
    padding: 5px 20px;
    background-color: #fff;
    cursor: pointer;
  }
  
  .footer .links {
    margin-bottom: 20px;
  }
  
  .footer .links a {
    display: block;
    color: #000;
    text-decoration: none;
    margin: 5px 0;
  }
  
  .footer .brand {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .footer .brand img {
    height: 100px;
    margin-right: 10px;
  }
  
  .footer .brand h4 {
    margin: 0;
    font-size: 24px;
  }
  
  .footer .brand p {
    margin: 0;
    font-size: 14px;
  }
  
  .footer .copyright {
    margin-top: 20px;
    color: #000;
  }
  .name{
    opacity: 0;
  }
  .name:hover{
    opacity: 1;
    transition: opacity 0.5s ease;
  }