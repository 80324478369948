.sidebar {
    position: fixed;
    top: 0;
    left: -250px; /* Hide off-screen */
    height: 100%;
    width: 250px;
    background-color: white; /* Clear background for the sidebar */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transition: left 0.3s ease;
    z-index: 1000;
  }
  
  .sidebar.open {
    left: 0; /* Show the sidebar */
  }
  
  .sidebar-content {
    padding: 20px;
  }
  
  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(5px); /* Blur effect on the background */
    z-index: 500; /* Ensure the overlay is above the background */
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 30px;
    color: #000;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  
  .beonscreen{
    position: sticky;
    top: 10%;
  }