body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.product-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.price {
    font-size: 18px;
    color: #333;
    margin-top: 10px;
}

.discounts p {
    margin: 5px 0;
    color: #e63946;
}

.size-options {
    margin-top: 20px;
}

.size-options button {
    margin-right: 10px;
    padding: 8px 16px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.size-options button:hover {
    background-color: #ddd;
}

.add-to-bag {
    margin-top: 20px;
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    transition: background-color 0.2s ease;
}

.add-to-bag:hover {
    background-color: #333;
}

.add-to-wishlist {
    margin-top: 10px;
    color: #000;
    cursor: pointer;
    transition: color 0.2s ease;
}

.add-to-wishlist:hover {
    color: #e63946;
}

.product-images img {
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.product-images img:hover {
    transform: scale(1.1);
}

.main-image-container {
    position: relative;
  }

  /* Grey background when zooming */
  .zoom-background {
    background-color: rgba(128, 128, 128, 0.5); /* Grey background with transparency */
    transition: background-color 0.3s ease; /* Smooth transition */
  }

  .main-image {
    position: relative; 
    cursor: zoom-in;
  }

  .main-image img {
    transition: opacity 0.3s ease-in-out;
  }

  .zoomed-image {
    position: absolute;
    top: 14%; /* Place zoomed image above the main image */ 
    transform: scale(1.5); /* Zoom effect */
    pointer-events: none; /* Disable interactions */ 
    cursor:zoom-in;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;  
  }
  .zoomed-image.hidden {
    opacity: 0;
    pointer-events: none; /* Ensures no interaction with the hidden image */
  }

  .zoomed-image img {
    width: 150%; /* Larger size for zoom effect */
    
  }
 @media(max-width : 786px){
    .heading{
        text-align: center; 
    }
    .price{
        font-size :medium;
    }
    .size{
        display: none;
    }
    .container .product-card{
        margin: 0rem 0rem !important;
        margin-bottom: 2rem !important;
    }
 }
 @media (min-width: 900px){
    .container .product-card{
        margin: 0rem 2rem !important;
        margin-bottom: 2rem !important;
    }
 }
 @media (max-width :586px){
    .product-title{
        text-align: start;
    }
    .added{
        font-size: 16px !important;
    }
 }
 /* ----------------Cart button css --------------------------- */


 .cart-button {
    position: relative;
    /* width: 240px; */
    height: 40px;
    border-radius: 8px;
    color: #fff;
    background: #006995;
    overflow: hidden;
    cursor: pointer;
    transition: .3s ease;
}

.cart-button:active {
    transform: scale(.9);
}

.cart-button .fa-shopping-cart {
    position: absolute;
    top: 50%;
    left: -10%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-size: 1.5rem;
}

.cart-button .fa-box {
    position: absolute;
    top: -20%;
    left: 52%;
    transform: translate(-50%, -50%);
    z-index: 3;
    font-size: 1rem;
}

.cart-button .add-to-cart,
.cart-button .added {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font: 700 18px;
}

.cart-button .added{
    opacity: 0;

}
.add-to-cart , .added{
    font-size: 1rem;
}

.cart-button.clicked .fa-shopping-cart {
    animation: cart 1.5s ease-in-out forwards;
}

.cart-button.clicked .fa-box {
    animation: box 1.5s ease-in-out forwards;
}

.cart-button.clicked .add-to-cart {
    animation: text1 1.5s ease-in-out forwards;
}

.cart-button.clicked .added {
    animation: text2 1.5s ease-in-out forwards;
}
 @media (max-width:486px){
    .cart-button .added , .add-to-wishlist{
        font-size: 14px !important;
    }    
}
@keyframes cart {
    0% {
        left: -10%;
    }

    40%, 60% {
        left: 50%;
    }

    100% {
        left: 110%;
    }
}

@keyframes box {
    0%, 40% {
        top: -20%;
    }

    60% {
        top: 40%;
        left: 52%;
    }

    100% {
        top: 40%;
        left: 112%;
    }
}

@keyframes text1 {
    0% {
        opacity: 1;
    }

    20%, 100% {
        opacity: 0;
    }
}

@keyframes text2 {
    0%, 80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


/* -----------------wishlist button style----------------- */

.like-button .heart-icon {
    height: 100px;
    width: 100px;
    background: url("../../../public/images/heart.png");
    background-position: left;
    cursor: pointer;
    position: absolute;
  }
  
  .like-button .heart-icon.liked {
    animation: like-anim 0.7s steps(28) forwards;
  }
  
  @keyframes like-anim {
    to {
      background-position: right;
    }
  }
  
  .like-button .heart-bg {
    background: rgba(255, 192, 200, 0);
    border-radius: 50%;
    height: 25px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 100ms ease;
    padding: .5rem;
  }
  
  /* .like-button .heart-bg:hover {
    background: rgba(255, 192, 200, 0.7);
  } */
  
  .like-button {
    display: flex;
    align-items: center;
  }
  
  .like-button .likes-amount {
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    color: #888;
    font-weight: 900;
    margin-left: 0px;
  }
  .add-to-wishlist{
    margin-right: 3rem;
    margin-top: 0;
  }