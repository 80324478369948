/* Style for the Navbar */
.custom-navbar {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  white-space: nowrap; 
  z-index: 0 !important;
  }
  .custom-navbar:hover{
    z-index: 2000 !important;
  }

/* Main styling for the navbar links */
.navbar-nav {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

/* Dropdown menu styling */
.custom-dropdown-menu {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 10px;
  position: absolute;
  top: 100%; /* Position just below the nav link */
   width: auto;
}

/* Adjustments for mobile */
@media (max-width: 991.98px) {
  .dropdown-menu {
    display: none; /* Hide by default */
  }

  .dropdown-menu.show {
    display: block; /* Show on click */
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
   }
}

/* Hover on desktop screens */
@media (min-width: 992px) {
  .nav-item:hover .custom-dropdown-menu {
    display: block;
  }
}
/* Media queries for responsive width adjustments */
@media (max-width: 1200px) {
  .nav-link {
    font-size: 0.9em;
    padding: 8px 10px; 
  }
}

@media (max-width: 992px) {
  .nav-link {
    font-size: 0.85em;
    padding: 6px 8px;
    margin: 0px 0px;
  }
  .navbar-nav .nav-item:last-child .custom-dropdown-menu {
    left: auto;
    right: 0;
  }
  .custom-dropdown-menu {
    font-size: 12px;
    padding-left: 0;
  }
}

@media (max-width: 768px) {
  .nav-link {
    font-size: 0.8em;
    padding: 4px 6px;
    margin: 0 8px !important;
  }
  .nav-item{
    margin: 0px 10px !important;
  }
  .navbar-nav .nav-item:last-child .custom-dropdown-menu {
    left: auto;
    right: 0;
  }
  .custom-dropdown-menu {
    font-size: 12px;
    padding-left: 0;
  }
}

@media (max-width: 576px) {
  .nav-link {
    font-size: 0.75em;
    /* padding: 2px 4px; */
    /* margin: 0 5px; */
  }
  .nav-item{
    margin: 0px -1px !important;
  }
  .navbar-nav .nav-item:last-child .custom-dropdown-menu {
    left: auto;
    right: 0; 
  }
  .custom-dropdown-menu {
    font-size: 12px;
    padding-left: 0;
  }
}

.dropdown-item {
  color: grey !important;
  font-size: 0.95em;
  font-family: "Inconsolata", serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  cursor: pointer;
 }

.dropdown-item:hover {
  color: #ff6b6b;
  background-color: rgba(255, 107, 107, 0.1);
}
.c .nav-link{
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
  color: #7a3331 !important;
}

 