/* header.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
  }
  .navbar .navbar-nav {
    flex-wrap: wrap;
  }
  .navbar .nav-item {
    margin: 0 10px;
  }
  
  .updock-regular{
    font-family: "Updock", cursive;
    font-weight: 400;
    font-style: normal;
  }
  .cart-number{
    font-size: 12px;
    position: relative;  
  }
  
  
  .navbar-brand {
    display: flex;
    align-items: center;
  }
  
  .d-flex.w-100 {
    flex: 1; /* Allows the search input to take the remaining space */
  }
  
  .d-lg-none {
    display: none; /* Hide on larger screens */
  }
  
  /* Ensure the search input does not push the logo down */
  @media (max-width: 992px) {
    .navbar {
      flex-wrap: nowrap; /* Prevent wrapping */
    }
  
    .form-control {
      min-width: 200px; /* Set a minimum width for the search input */
    }
  }
  @media (max-width: 992px) {
    .navbar-toggler {
      display: block !important;
    }
  }
  @media (max-width : 546px){
    .navbar-brand .title{
      font-size: 26px !important;
      } 
    .nav-item .nav-link .fas{
      font-size: 20px !important;
      margin: 0rem -.2rem 0rem 0rem !important;
     }
    .navbar .btn{
      font-size: 16px !important;
      border: none !important;
      padding: 0rem 0rem 0rem 0rem !important;
    }
    .btn:last-child{
      margin-right: 1rem !important;
    }
    .cart-number{
      font-size: 9px;
    }
    .ul{
      padding: 0rem 0rem !important;
    }
     
  }
.input:focus, 
textarea:focus, 
select:focus {
  outline:none !important;
  box-shadow: none !important; /* Remove any additional shadows */
}


  .product-list {
    top: 70px !important; /* Adjust depending on the navbar height */
    right: 0;
    width: 100%; /* Adjust width as necessary */
    z-index: 1000;
  }
  
  .product {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
  
  .product img {
    border-radius: 4px;
  }
  
  .product h5 {
    font-size: 16px;
  }
  
  .product p {
    font-size: 12px;
    color: #6c757d;
  }
  /* Make sure your search input and product list are responsive */
.form-control {
  max-width: 300px; /* Adjust as needed */
}

 

.product-list .product {
  white-space: nowrap; /* Prevent text wrapping */
}
.product-list {
  right: 5%; /* Align to the right side */
  top: 90%; /* Position it below the search bar */
  max-width: 300px; /* Optional: max width for the dropdown */
  overflow-y: auto; /* Allow scrolling if needed */
  z-index: 1000;
}

@media (max-width:546px){
  .product img{
    height: 100px !important;
    margin: 0;
  }
  .product{
    display: flex;
    justify-content: center;
  }
  .product-list{
    top:100%;
  }
  .form-control{
    min-width: 100px !important;
   }
}